@import '../vars';

.videoBanner-container {
  position: relative;
  height: 56.25vw;
  width: 100vw; /* 100 * 16 / 9 */
  min-width: 100%;
  min-height: 56.25vw; /* 100 * 9 / 16 */
  overflow: hidden;
}
.videoBanner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
  video {
    position: relative;
    left: 50%; /* % of surrounding element */
    top: 50%;
    transform: translate(-50%, -50%); /* % of current element */
    object-fit: cover;
  }
}

.videoBanner-title {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 200px;
  top: 50%;
  transform: translate(0, -100px);
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
