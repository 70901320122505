$sm: 560px;
$md: 768px;
$lg: 960px;
$xl: 1170px;

@mixin xs {
  @media (max-width: $sm) {
    @content;
  }
}
@mixin sm {
  @media (min-width: $sm) {
    @content;
  }
}
@mixin md {
  @media (min-width: $md) {
    @content;
  }
}
@mixin lg {
  @media (min-width: $lg) {
    @content;
  }
}
@mixin xl {
  @media (min-width: $xl) {
    @content;
  }
}

@mixin wide {
  @media (min-aspect-ratio: 16/9) {
    @content;
  }
}

@mixin vertical {
  @media (max-aspect-ratio: 16/9) {
    @content;
  }
}
