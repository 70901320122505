@import '../vars';
.members-container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding: 30px;
  max-width: 1200px;
  margin: 0 auto;
  @include md {
    flex-direction: row;
    padding: 50px;
  }
}
.members-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0 120px 0;

  @include md {
    flex-direction: row;
    width: 48%;
    padding: 0;
  }
}

.members-photo {
  width: 100%;
  margin: 0 0 20px 0;
  text-align: center;
  @include md {
    width: 40%;
    margin: 0 20px 0 0;
  }

  img {
    width: 100%;
    max-width: 260px;
    height: auto;
  }
}

.members-bio {
  font-size: 24px;
  font-weight: 300;
  color: #ffffff;
  line-height: 30px;
  width: 100%;
  @include lg {
    font-size: 30px;
    line-height: 40px;
  }
  @include md {
    width: 60%;
    font-size: 26px;
    line-height: 30px;
  }
}
