@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap);
body{margin:0;font-family:'Nunito', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}

.main{width:100vw;min-height:100vh;height:auto;background-color:#000000}.home__contact-us{color:#ffffff;margin:24px auto 0;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;font-size:30px}.home__contact-us a{color:#ffffff}.home__ccopyright{color:#ffffff;margin:100px auto 0;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;font-size:20px}

.videoBanner-container{position:relative;height:56.25vw;width:100vw;min-width:100%;min-height:56.25vw;overflow:hidden}.videoBanner{position:absolute;top:0;left:0;width:100%;height:auto;z-index:1}.videoBanner video{position:relative;left:50%;top:50%;transform:translate(-50%, -50%);object-fit:cover}.videoBanner-title{position:absolute;z-index:2;width:100%;height:200px;top:50%;transform:translate(0, -100px);background-color:rgba(0,0,0,0.8);display:flex;align-items:center;justify-content:center}

.bio-text{font-size:30px;font-weight:300;color:#ffffff;line-height:40px;text-align:center;padding:30px;max-width:1200px;margin:0 auto}@media (min-width: 768px){.bio-text{padding:50px;font-size:40px;line-height:40px}}@media (min-width: 960px){.bio-text{padding:50px;font-size:50px;line-height:60px}}.bio-text a{color:#ffffff;text-decoration:underline}.bio-text a:hover{text-decoration:none}

.members-container{display:flex;flex-direction:column-reverse;justify-content:space-between;padding:30px;max-width:1200px;margin:0 auto}@media (min-width: 768px){.members-container{flex-direction:row;padding:50px}}.members-column{width:100%;display:flex;flex-direction:column;padding:0 0 120px 0}@media (min-width: 768px){.members-column{flex-direction:row;width:48%;padding:0}}.members-photo{width:100%;margin:0 0 20px 0;text-align:center}@media (min-width: 768px){.members-photo{width:40%;margin:0 20px 0 0}}.members-photo img{width:100%;max-width:260px;height:auto}.members-bio{font-size:24px;font-weight:300;color:#ffffff;line-height:30px;width:100%}@media (min-width: 960px){.members-bio{font-size:30px;line-height:40px}}@media (min-width: 768px){.members-bio{width:60%;font-size:26px;line-height:30px}}

