@import '../vars.scss';

.bio-text {
  font-size: 30px;
  font-weight: 300;
  color: #ffffff;
  line-height: 40px;
  text-align: center;

  padding: 30px;
  max-width: 1200px;
  margin: 0 auto;
  @include md {
    padding: 50px;
    font-size: 40px;
    line-height: 40px;
  }
  @include lg {
    padding: 50px;
    font-size: 50px;
    line-height: 60px;
  }

  a {
    color: #ffffff;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
