.main {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  background-color: #000000;
}

.home__contact-us {
  color: #ffffff;
  margin: 24px auto 0;
  width: fit-content;
  font-size: 30px;
  a {
    color: #ffffff;
  }
}

.home__ccopyright {
  color: #ffffff;
  margin: 100px auto 0;
  width: fit-content;
  font-size: 20px;
}
